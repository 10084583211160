import React from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import "./onlineCustomers.css";
import {useDispatch, useSelector} from "react-redux";
import toastr from "toastr";
import {depositCashierCash, getUserInfo, withdrawalCashierCash} from "../../Common/repository";
import {setBalance} from "../../../store/Cashbalance/action";
export const OnlineCustomers = () => {
    let dispatch = useDispatch();
    const OnlineCustomer = useSelector((state) => state?.OnlineCustomer?.onlineCustomers ?? []);

    const deposit = (id, amount, idElement) => {
        try {
            var loggedInUser = JSON.parse(localStorage.getItem("users"))?.id;
            depositCashierCash(id,loggedInUser, { amount: amount })
                .then(res => {
                    toastr.success(res?.data?.message)
                    $('#'+idElement).val('')
                    getUserInfo().then((res) => {
                        dispatch(setBalance(res?.data?.data?.cash_balance))
                    })
                })
                .catch(error => {
                    toastr.error(error?.response?.data?.message)
                })
        } catch (error) {
            toastr.error("User not found")
        }

    }


    const withdrawal = (id, amount, idElement) => {
        try {
            var loggedInUser = JSON.parse(localStorage.getItem("users"))?.id;
            withdrawalCashierCash(id,loggedInUser, { amount: amount })
                .then(res => {
                    toastr.success(res?.data?.message)
                    $('#'+idElement).val('')
                    getUserInfo().then((res) => {
                        dispatch(setBalance(res?.data?.data?.cash_balance))
                    })
                })
                .catch(error => {
                    toastr.error(error?.response?.data?.message)
                })
        } catch (error) {
            toastr.error("User not found")
        }
    }

    return (
        <AppLayout>
            {
                OnlineCustomer?
                    <div className="row ">
                        <div className="col-lg-9 row-main p-4">
                            <div className="card">
                                <div className="card-title">
                                    <div className="card-title mb-4">User Details</div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="col-form-label">User ID</label>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted fw-medium col-form-label">
                                            {OnlineCustomer?.id}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="col-form-label">Mobile Number</label>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted fw-medium col-form-label">
                                            {OnlineCustomer?.phone_number}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="col-form-label">User Name</label>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted fw-medium col-form-label">
                                            {OnlineCustomer?.first_name} {OnlineCustomer?.last_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <label className="col-form-label">Status</label>
                                    </div>
                                    <div className="col">
                                        <p className="text-muted fw-medium col-form-label">

                                            {OnlineCustomer?.user_status == 0 ? 'Inactive' : (OnlineCustomer?.user_status == 1 ? 'Active' : '' )}
                                        </p>
                                    </div>
                                </div>
                                {
                                    (OnlineCustomer?.id && OnlineCustomer?.user_status == 1) ?
                                        <div className="row mb-3">
                                            <div className="col">
                                                <label className="col-form-label">Action</label>
                                            </div>
                                            <div className="col">
                                                <div className="input-group" style={{width: '62%',marginBottom: '7px'}}>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={`inputGroupSendMoney${OnlineCustomer?.id}`}
                                                        aria-describedby="inputGroupSendMoney"
                                                        placeholder="Deposit Money"
                                                        style={{fontSize:"inherit"}}
                                                    />
                                                    <button
                                                        className="btn btn-success"
                                                        type="button"
                                                        id="inputGroupSendMoney"
                                                        onClick={(event) => {
                                                            deposit(OnlineCustomer?.id, $(`#inputGroupSendMoney${OnlineCustomer?.id}`).val(),`inputGroupSendMoney${OnlineCustomer?.id}`)
                                                        }}
                                                        style={{fontSize:"inherit"}}
                                                    >
                                                        Deposit Money
                                                    </button>
                                                </div>
                                                {(OnlineCustomer?.cash_balance && OnlineCustomer?.cash_balance!=0)?
                                                    <div className="input-group" style={{width: '62%',marginBottom: '7px'}}>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`inputGroupCollectMoney${OnlineCustomer?.id}`}
                                                            aria-describedby="inputGroupCollectMoney"
                                                            placeholder="Withdraw Money"
                                                            style={{fontSize:"inherit"}}
                                                        />
                                                        <button
                                                            type="submit"
                                                            className="btn btn-danger"
                                                            id="inputGroupCollectMoney"
                                                            onClick={() => {
                                                                withdrawal(OnlineCustomer?.id, $(`#inputGroupCollectMoney${OnlineCustomer?.id}`).val(),`inputGroupCollectMoney${OnlineCustomer?.id}`)
                                                            }}
                                                            style={{fontSize:"inherit"}}
                                                        >
                                                            Withdraw Money
                                                        </button>
                                                    </div>
                                                    : ''
                                                }

                                            </div>
                                        </div>
                                        : ''
                                }

                            </div>
                        </div>
                    </div>
                    :''
            }

        </AppLayout>
    );
};
