import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setBets } from "../../../store/Betslip/actions";
import ScrollButton from "../../Common/ScrollButton";

function FixtureDetails(props) {
  const { fixture } = props;
  let dispatch = useDispatch();
  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const getColumns = (l) => {
    if (l > 3) {
      return "col-4";
    }
    return "col";
  };

  const checkIfOddIsSelected = (f, fm, odd) => {
    if (Bets.length && odd) {
      return (
        Bets.find((b) => b._id == f._id)
          ?.fixture_markets?.find((fmd) => fmd._id == fm._id)
          ?.fixture_market_odds?.find((o) => o._id == odd._id)?.selected ??
        false
      );
    }
    return false;
  };

  const _removeOddFromBetslip = (fixture) => {
    dispatch(setBets(_.cloneDeep(Bets).filter((fd) => fd._id != fixture._id)));
  };

  const _renderMarketName = (f, value) => {
    let text = value.name;

    text = text.replaceAll("{$competitor1}", f.competitors[0].name);
    text = text.replaceAll("{$competitor2}", f.competitors[1].name);
    return (
        text +
        (value.special_bet_value && value.special_bet_value != "-1"
            ? "[" + value.special_bet_value + "]"
            : "")
    );
  };

  const _renderOddName = (f, odd) => {
    if (odd.player) {
      return odd.player.name;
    }
    let text = odd?.market_spec?.name ?? odd?.market_spec?.bb_id;
    text = text.replaceAll("{$competitor1}", f.competitors[0].name);
    text = text.replaceAll("{$competitor2}", f.competitors[1].name);
    text = text.replaceAll("{total}", odd.special_bet_value);
    if (text == "o") {
      return "Over";
    } else if (text == "u") {
      return "Under";
    }
    return text;
  };

  const _addToBetslip = (fixture, fixtureMarket, odd) => {
    let copyFixture = _.cloneDeep(fixture);
    let oddsData = copyFixture.fixture_markets
      .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
      .fixture_market_odds.map((odd) => {
        odd.selected = false;
        return odd;
      });

    copyFixture.fixture_markets.find(
      (fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id
    ).odds = oddsData;

    copyFixture.fixture_markets
      .find((fixtureMarketData) => fixtureMarketData._id == fixtureMarket._id)
      .odds.find((oddData) => oddData._id == odd._id).selected = true;

    let foundFixture = Bets.find((fix) => fix._id == fixture._id);
    if (foundFixture) {
      let d = _.cloneDeep(Bets);
      let index = d.findIndex((i) => i._id == fixture._id);
      if (index > -1) {
        d[index] = copyFixture;
      }
      dispatch(setBets(d));
    } else {
      dispatch(setBets([...Bets, copyFixture]));
    }
  };
  let orderArray=[
    '1x2',
    'Total',
    'Double Chance',
    'Draw No Bet',
    'Both teams to score',
    'Odd/Even',
    'Booking 1x2',
    'Correct score',
    'Corner 1x2',
    '1st half - 1x2',
    '1st half - total',
    '1st half - double chance',
    '1st half - draw no bet',
    '1st half - both teams to score',
    '1st half - odd/even',
    '1st half - correct score',
    '1st half - booking 1x2',
    '2nd half - 1x2',
    '2nd half - total',
    '2nd half - double chance',
    '2nd half - draw no bet',
    '12nd half - both teams to score',
    '2nd half - odd/even',
    '2nd half - correct score',
    'Which team to score',
    'Highest scoring half',
    'Halftime/fulltime correct score',
    'Halftime/fulltime',
    '1x2 and both teams to score',
    'Exact bookings',
    'Sending off',
    'Corner range',
    'Last corner',
    '{competitor1} corner range',
    '{competitor2} corner range',
    'Last goal',
    'Winning margin',
    '{competitor1} win to nil',
    '{competitor2} win to nil',
  ];

  if(fixture.fixture_markets){
    const FixtureDetail1 = [...fixture.fixture_markets
    ].sort((a, b) => {

      const indexA = orderArray.findIndex(id => id === a.market.name || String(id).toLowerCase() === String(a.market.name).toLowerCase());
      const indexB = orderArray.findIndex(id => id === b.market.name || String(id).toLowerCase() === String(b.market.name).toLowerCase());


      if (indexA === -1 && indexB === -1) {
        return a.fixture_bb_id
            .localeCompare(b.fixture_bb_id
                , undefined, { sensitivity: 'base' } ); // Sort alphabetically if both items are unmatched
      }

      if (indexA === -1) {
        return 1; // a is unmatched, move it down
      }

      if (indexB === -1) {
        return -1; // b is unmatched, move it down
      }


      return indexA - indexB;
    });
    fixture.fixture_markets=FixtureDetail1;
  }

  const _renderOddsData = (f, fixtureMarket, marketName) => {
     let classFor = ["1x2","Double chance","1st half - 1x2","1x2 (incl. overtime)","Exact sets","1st half - double chance","Booking 1x2","Corner 1x2","1st half - booking 1x2","2nd half - 1x2","2nd half - double chance","Corner range","Last corner","Last goal"]
    let marketNameForAllowValue= ["Total","1st half - total","2nd half - total"];

      if (fixture?.tournament?.sport_id == 12 || fixture?.tournament?.sport_id == 1) {
      classFor.push("Highest scoring half");
    }

    let myOdds = fixtureMarket.fixture_market_odds;
    let tempOddNames = myOdds?.map((o) => o?.name);
    tempOddNames = [...new Set(tempOddNames ?? [])];
    return tempOddNames?.map((ton) => {
      return (
          <div className={`row ${getColumns(tempOddNames.length)}`}>
            {_.sortBy(myOdds,'special_bet_value')
                ?.filter((o) => o?.name == ton)
                .map((odd,index) => {
                  return (
                      (String(odd.special_bet_value).split('.').pop() != '25' && String(odd.special_bet_value).split('.').pop() != '75' && (marketNameForAllowValue.indexOf(marketName) > -1 ? ((odd.special_bet_value  % 1 === 0.5 && odd.special_bet_value >= 1) ? true : false) : true))
                          ?
                          <div key={index} className={"inline-div col-" + (classFor.indexOf(marketName) > -1 ? '4' : '6')}>
                            <button
                                className={
                                  checkIfOddIsSelected(f, fixtureMarket, odd)
                                      ? `BLM-btnOdds  BLM-btnOutComeOdds active`
                                      : `BLM-btnOdds BLM-btnOutComeOdds`
                                }
                                disabled={odd ? (odd.value == "OFF" ? true : false) : true}
                                // className="btn BLM-btnOdds BLM-btnOutComeOdds"
                                individualevent-odds-incdec="N"
                                id="highlightBet_328088483"
                                onClick={() => {
                                  if (checkIfOddIsSelected(f, fixtureMarket, odd)) {
                                    _removeOddFromBetslip(f);
                                  } else {
                                    _addToBetslip(f, fixtureMarket, odd);
                                  }
                                }}
                            >
                    <span className="BLM-outcome">
                      {_renderOddName(f, odd)}
                      {odd?.special_bet_value && odd.special_bet_value != "-1"
                          ? "[" + odd.special_bet_value + "]"
                          : ""}
                      {/* ${
                      ![undefined, null, ""].includes(odd?.base_line)
                        ? "[" + odd?.base_line + "]"
                        : ""
                    }`} */}
                    </span>
                    <span className="BLM-odds">
                      {odd.value == "OFF"
                          ? odd.value
                          : parseFloat(odd?.value).toFixed(2)}{" "}
                    </span>
                            </button>
                          </div>
                          : ''

                  );
                })}
          </div>
      );
    });
  };

  const _renderFixtureDetail = (filter) => {
    return filter.map((fixture_market, index) => {
      return (
        <div className="BLM-marketBox BLM-accordion" id={`accordian_${index}`}>
          <div
            className="BLM-marketBox-header BLM-accordion-header BLM-arrowAfter"
            id={`market${index + 1}`}
            key={index}
            onClick={() => {
              $(`#accordian_${index}`).toggleClass("collapsed");
            }}
          >
            <div className="BLM-marketName">
              <span>
                {_renderMarketName(fixture, fixture_market?.market)}
              </span>
            </div>
          </div>
          <div className="BLM-accordion-content">
            <div className="BLM-marketBox-content">
              {/*<ul*/}
              {/*  className={`BLM-marketOutComeOddsList row`}*/}
              {/*  style={{ display: "flex" }}*/}
              {/*>*/}
                {_renderOddsData(fixture, fixture_market, _renderMarketName(fixture, fixture_market?.market))}
              {/*</ul>*/}
            </div>
          </div>
        </div>
      );
    });
  };

  return Object.keys(fixture)?.length ? (
    <div className="BLM-eventPage-content">
      {_renderFixtureDetail(fixture?.fixture_markets)}
    </div>
  ) : null;

}

export default withRouter(FixtureDetails);
