import _ from "lodash";
import React, { useEffect, useState, useRef } from "react";
import "./betslip.css";
import { useReactToPrint } from "react-to-print";
import toastr from "toastr";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {getRegulatoryAndParameters, getTicketBetData, getUserBalance, getUserInfo, placeBetCall} from "./repository";
import { showMessage } from "./Toaster";
import { setBets } from "../../store/Betslip/actions";
import { ComponentToPrint } from "../Pages/Reciept/BetSlipReciept";
import {currency} from "./constants";
import startCase from "lodash/startCase";
import {logDOM} from "@testing-library/react";
import {setBalance} from "../../store/Cashbalance/action";
import BookingID from "../../store/BookingId/reducer";
export const Betslips = (props) => {
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;
  const Bets = useSelector((state) => state?.Betslip?.bets ?? []);
  const bookingID = useSelector((state) => state?.BookingID?.bookingID ?? '');
  let dispatch = useDispatch();
  const [totalOdds, setTotalOdds] = useState(0);
  const [stake, setStake] = useState(0);
  const [exciseTaxPercentage, setExciseTaxPercentage] = useState(null);
  const [exciseTax, setExciseTax] = useState(0);
  const [WHTPercentage, setWHTPercentage] = useState(null);
  const [bonusPercentage, setBonusPercentage] = useState(null);
  const [addMoreCount, setAddMoreCount] = useState(null);
  const [bonusPercent, setBonusPercent] = useState(null);
  const [selectedPercent, setSelectedPercent] = useState(0);
  const [WHT, setWHT] = useState();
  const [WIN, setWIN] = useState(0);
  const [minimumBet, setMinimumBet] = useState(null);
  const [maximumBet, setMaximumBet] = useState(null);
  const [isAllPrematch, setIsAllPrematch] = useState(false);
  const [useBonus, setUseBonus] = useState(false);
  const [keepBet, setKeepBet] = useState(false);
  const [acceptOddsChange, setAcceptOddsChange] = useState(false);
  const [notApplicableOdds, setNotApplicableOdds] = useState(0);
  const [selectedBonus, setSelectedBonus] = useState(0);
  const [maximumPayout, setMaximumPayout] = useState(null);
  const [userBalance, setUserBalance] = useState(0);
  const [isBalanceLess, setIsBalanceLess] = useState(false);
  const [minimumOdds, setMinimumOdds] = useState(null);
  const [multiBonusPercent, setMultiBonusPercent] = useState(0);
  const [loading, setLoading] = useState(null);
  const [note, setNote] = useState('');
  const [openModel, setModelOpen] = useState(false);
  const [totalOddNew, setTotalOddNew] = useState(0);
  const [odds, setOdds] = useState(null);
  const [multiBonusAmount, setMultiBonusAmount] = useState(0);
  const [printData, setPrintData] = useState([]);
  const [bookingId, setBookingId] = useState(bookingID);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const callTicket = (id) => {
    getTicketBetData(id).then((res) => {
      setPrintData(res?.data?.data[0] ?? [])
      handlePrint()
    })
  }

  const CountDown = ({ hours = 0, minutes = 0, seconds = 0 }) => {

    const [over, setOver] = useState(false);
    const [[h, m, s], setTime] = useState([hours, minutes, seconds]);

    const tick = () => {
      if (over) return;
      if (h === 0 && m === 0 && s === 0) {
        setOver(true);
        // setModelOpen(false);
        setLoading(0);
      }
      else if (m === 0 && s === 0) {
        setTime([h - 1, 59, 59]);
      } else if (s == 0) {
        setTime([h, m - 1, 59]);
      } else {
        setTime([h, m, s - 1]);
      }
    };

    useEffect(() => {
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    });

    return (
        <div style={{ 'text-align': "center", 'margin-top' : '10px' }}>
          <h6 style={{ color: "#ff0000" }}>{`${h.toString().padStart(2, '0')}:${m
              .toString()
              .padStart(2, '0')}:${s.toString().padStart(2, '0')}`}</h6>
        </div>
    );
  };

  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
    if(isLoggedIn) {
      getUserInfo(handleSuccessGotUser, errorUserInfoCall);
      setUserBalance(getUserBalance());
    }

  }, []);

  const errorUserInfoCall = (e) => {
    showMessage(e);
  };
  useEffect(() => {
    let BetOdd = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      return sendBet;
    });
    const bookDetail = JSON.parse(localStorage.getItem("book_bet_detail"));


    if(BetOdd?.length == bookDetail?.odds?.length){
      const obj1Ids = BetOdd.map(item => item._id);
      const obj2Ids = bookDetail?.odds.map(item => item._id);
      const areAllIdsSame = obj1Ids.every(id => obj2Ids.includes(id)) && obj2Ids.every(id => obj1Ids.includes(id));
      if(areAllIdsSame){
        setBookingId(bookDetail?.booking_id)
      }
    }
    else{
      setBookingId(null)
    }
  },[Bets]);
  const _handleSubmitBet = () => {
    setLoading(1)
    // if (!isLoggedIn) {
    //   history.push(`/login`);
    //   return;
    // }
    if (parseFloat(stake) < minimumBet) {
      toastr.error(`Stake should be Greater Or Equal to ${minimumBet}`);
      return;
    } else if (parseFloat(stake) > maximumBet) {
      toastr.error(`Stake should be Less Or Equal to ${maximumBet}`);
      return;
    }
    var live_array=[];
    let odds = Bets.map((bet) => {
      return _getPlacedBet(bet.fixture_markets);
    }).map((bet) => {
      let sendBet = {};
      sendBet._id = bet._id;
      sendBet.value = parseFloat(bet.value);
      sendBet.base_line = bet.special_bet_value;
      if (typeof bet.is_live === "undefined") {
        live_array.push(null)
      }
      else{
        live_array.push(bet.is_live)
      }
      return sendBet;
    });
    const allEqual = arr => arr.every(val => val === arr[0]);
    if(!allEqual(live_array)){
      toastr.error(`All odds are either of live match or upcoming match`);
      return;
    }
    var toWin=_getPossibleWin();
    if(parseFloat(toWin)>parseFloat(maximumPayout)){
      toastr.error(`Win Amount Must be less than ${maximumPayout},please remove some of matches`);
      return;
    }
    if(parseFloat(toWin) <= 0) {
      toastr.error(`To Win should be greater than zero`);
      return;
    }
    if(parseFloat(stake) <= 0) {
      toastr.error(`Stake should be greater than zero`);
      return;
    }

    let data = {};
    data.odds = odds;
    data.stake = stake;
    data.accept_odds_change = acceptOddsChange;
    data.is_live = (live_array[0]==null) ? '0' : '1';
    data.bonus_amount = (selectedBonus != 0) ? selectedBonus : null;
    data.wht = _getPossibleWHT();
    data.to_win = _getPossibleWin();
    // data.use_bonus = useBonus;
    data.percentageCurrent = selectedPercent;
    data.is_balance_less = isBalanceLess;
    data.minimum_odds = minimumOdds;
    data.booking_code = bookingId;
    // data.
    placeBetCall(data, handleSuccessPlaceBet, handleErrorPlaceBet);
  };

  useEffect(() => {
    if(openModel){
      $('#exampleModalCenter').modal('show');
    }
    else{
      $('#exampleModalCenter').modal('hide');
    }
  },[openModel])

  const _submitBet = () => {
    let data = JSON.parse(localStorage.getItem("store_data"));
    // data.
    placeBetCall(data, handleSuccessPlaceBet, handleErrorPlaceBet);
  }

  const handleSuccessPlaceBet = (response) => {
    let res = response.data.data;
    
    setNote('');
    setModelOpen(false);

    if(res.is_difference) {

      let size = Object.keys(res.odds).length;

      if(res.bonus_amount && (parseFloat(res.bonus_amount) != parseFloat(selectedBonus))) {
        setNote("Required: Selection  with "+minimumOdds+"+ odds");
      } else  if(size < Bets.length) {
        setNote('Some matches or markets have closed');
      }

      setOdds(res.odds)
      setTotalOddNew(res.total_odds);
      setStake(res.stake);
      setWHT(res.wht);
      setMultiBonusPercent(res.percentageCurrent);
      setMultiBonusAmount((res.bonus_amount) ? res.bonus_amount : 0);
      setWIN(res.to_win);

      localStorage.removeItem("store_data");

      let data = {};
      data.odds = res.odds;
      data.stake = res.stake;
      data.accept_odds_change = res.accept_odds_change;
      data.is_live = res.is_live;
      data.bonus_amount = res.bonus_amount;
      data.wht = res.wht;
      data.to_win = res.to_win;
      data.percentageCurrent = res.percentageCurrent;
      data.minimum_odds = res.minimum_odds;

      localStorage.setItem("store_data", JSON.stringify(data));
      setModelOpen(true);

    } else {
      if (!keepBet) {
        dispatch(setBets([]));
      }
      setLoading(0)
      toastr.success(`Bet Placed Successfully`);
      callTicket(res?.id)
    }
    getUserInfo().then((res) => {
      dispatch(setBalance(res?.data?.data?.cash_balance))
    })
  };

  const handleErrorPlaceBet = (error) => {
    setNote('');
    setModelOpen(false);
    setLoading(0)
    toastr.error(error);
  };

  const handleSuccessGotUser = (res) => {
    setFirstDeposit(res?.data?.first_deposit);
    // setBonusAmount(res?.data?.bonus_balance);
  };

  const handleSuccess = (res) => {
    setMinimumOdds(res?.multi_bonus?.minimum_odds);
    setBonusPercentage(res.multi_bonus?.percentages);
    setStake(parseFloat(res.betting_limits.minimum_bet));
    setMinimumBet(parseFloat(res.betting_limits.minimum_bet));
    setMaximumBet(parseFloat(res.betting_limits.maximum_bet));
    setExciseTaxPercentage(parseFloat(res.regulatory.sales_excise_tax));
    setWHTPercentage(parseFloat(res.regulatory.wht_winning_tax));
    setMaximumPayout(res.betting_limits.maximum_payout);
  };

  useEffect(() => {
    if (Bets.length) {
      let allOdds = Bets.map((b) =>
          parseFloat(_getPlacedBet(b.fixture_markets).value).toFixed(2)
      );
      let is_live = Bets.map((b) =>
          _getPlacedBet(b.fixture_markets).is_live
      );
      const allEqual = arr => arr.every(val => val == arr[0]);
      const allEqualPrematch = arr => arr.every(val => (val == null) || (val == false));

      setIsAllPrematch(allEqualPrematch(is_live));

      if(!allEqual(is_live)){
        setcheckLiveSelected(false)
      }
      let tempTotalOdds = 1;
      allOdds.forEach((ao) => {
        tempTotalOdds = tempTotalOdds * ao;
      });
      setTotalOdds(tempTotalOdds.toFixed(2));
    } else {
      setTotalOdds(0);
    }
  }, [Bets]);

  useEffect(() => {

    if(bonusPercentage && Bets.length && isAllPrematch==true){
      var betCount = Bets.length;

      let not_applicable_odds = 0;
      let allOdds = Bets.map((b) =>
          parseFloat(_getPlacedBet(b.fixture_markets).value).toFixed(2)
      );

      if(minimumOdds) {
        allOdds.forEach((ao) => {
          if(parseFloat(ao) < parseFloat(minimumOdds)) {
            not_applicable_odds++;
          }
        });
        setNotApplicableOdds(not_applicable_odds);
      }

      var bonus_bet_count = parseFloat(betCount) - parseFloat(not_applicable_odds);
      // percentage
      let percentages = JSON.parse(bonusPercentage)

      if(parseFloat(bonus_bet_count) == 0) {
        setSelectedPercent(0)
      } else {
        const selected = Object.values(percentages).find(e => e.selection === bonus_bet_count);
        setSelectedPercent(selected.value)
      }


      let currentUser
      let groupedMessages = []

      for (const selection of percentages) {
        if (selection.value !== currentUser) {
          groupedMessages.push([])
          currentUser = selection.value
        }
        groupedMessages[groupedMessages.length - 1].push(selection)
      }
      let pills = []
      groupedMessages.forEach(gp => {
        let from = gp[0].selection
        let to = gp[gp.length - 1].selection
        if (![undefined, null, ""].includes(gp[0].value) && from !== to) {
          pills.push({ from: from, to: to, value: gp[0].value })
        } else if (
            ![undefined, null, ""].includes(gp[0].value) &&
            from === to
        ) {
          pills.push({ from: from, value: gp[0].value })
        }
      })

      let does_go = 0;
      pills.map((item,index) => {
        if(item?.to){
          if(item.from <= bonus_bet_count && item.to >= bonus_bet_count){
            does_go = 1;
            var nextRange=pills[index+1];
            setAddMoreCount(nextRange.from - bonus_bet_count)
            setBonusPercent(nextRange.value)
          }
        }
      })

      if(does_go == 0) {
        var nextRange=pills[1];
        setAddMoreCount(nextRange.from - bonus_bet_count)
        setBonusPercent(nextRange.value)
      }

    } else {

      setAddMoreCount(null)
      setBonusPercent(null)
      setSelectedPercent(0)
      setNotApplicableOdds(0)
    }

  },[bonusPercentage,Bets,isAllPrematch])

  const _getPlacedBet = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return odds[0];
      }
    }
  };

  const _getMarketName = (markets) => {
    for (let i = 0; i < markets.length; i++) {
      let odds = markets[i].fixture_market_odds.filter((o) => o?.selected);
      if (odds.length) {
        return markets[i].market.name;
      }
    }
  };

  const _getPossibleWin = () => {
    let stakeAfterTax = stake - exciseTax;
    var wht =parseFloat(_getPossibleWHT());
    return ((((stakeAfterTax * parseFloat(totalOdds)) + parseFloat(selectedBonus)) - wht)).toFixed(2);
  };

  const _getPossibleWHT = () => {
    let stakeAfterTax = stake - exciseTax;
    let applyTax = (stakeAfterTax * totalOdds) + parseFloat(selectedBonus);

    let applyPercentWHTOnStakeAfterTax = ((applyTax-stakeAfterTax) / 100) * WHTPercentage;
    return applyPercentWHTOnStakeAfterTax.toFixed(2);
  }


  useEffect(() => {
    setExciseTax((stake / (100 + exciseTaxPercentage)) * exciseTaxPercentage);

  }, [stake, exciseTaxPercentage]);

  useEffect(() => {
    if(selectedPercent && selectedPercent!=0){
      // #% Boost = Stake after tax * TotalOdds - 10% [display the 10% value]
      var stakeAfterTax = (stake - exciseTax).toFixed(2);
      var stakeTax = stakeAfterTax * totalOdds;
      var selectPercent = parseFloat(((stakeTax * parseFloat(selectedPercent))/100));
      setSelectedBonus(selectPercent.toFixed(2));
    }
    else{
      setSelectedBonus(0)
    }
  }, [selectedPercent,stake,totalOdds,exciseTax]);

  const _renderOddName = (f, odd) => {
    if (odd.player) {
      return odd.player.name;
    }
    let text = odd?.market_spec?.name;
    text = text?.replaceAll("{$competitor1}", f?.competitors[0]?.name);
    text = text?.replaceAll("{$competitor2}", f?.competitors[1]?.name);
    return startCase(text);
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key]['_id'] === value);
  }

  const _renderBetslips = (can_use_all = 1) => {
    return Bets.map((betslip) => {
      let marketName = _getMarketName(betslip.fixture_markets);
      let selectedOdd = _getPlacedBet(betslip.fixture_markets);
      let can_go = false;
      let make_it_red = false;
      let selectedOddValue = parseFloat(selectedOdd?.value)?.toFixed(2);
      if(can_use_all) {
        can_go = true;
      }else if(odds){
        let res = JSON.parse(localStorage.getItem("store_data"));
        if(res) {
          Object.keys(odds).forEach(key => {
            if(odds[key]['_id'] == selectedOdd._id) {
              if((res.bonus_amount && (parseFloat(res.bonus_amount) != parseFloat(selectedBonus))) && odds[key]['value'] != selectedOdd.value) {
                make_it_red = true;
              }
              can_go = true;
              selectedOddValue = parseFloat(odds[getKeyByValue(odds,selectedOdd._id)]['value'])?.toFixed(2)
            }
          });
        }
      }

      return (
          <>
            <div class="BLM-betSlipBox" id="highlightCompletedMatchBet_0">
              <div class="BLM-betSlipBox-content w-100 p-2">
                <div class="BLM-bet-details">
                  <div class="BLM-betSlip-outcomeOdds">
                    <div class="BLM-betSlip-outcome truncate">
                      {_renderOddName(betslip, selectedOdd)}
                      {selectedOdd.special_bet_value
                          ? "[" + selectedOdd.special_bet_value + "]" //Added the special_bet_value from base_line
                          : ""}
                    </div>
                    <div className="BLM-betSlip-outcome truncate"
                         style={{color: (!can_go || make_it_red) ? 'red' : ''}}>
                      {selectedOddValue}
                    </div>
                  </div>
                  <div class="BLM-betSlip-market">{marketName}</div>
                  <div class="BLM-betSlip-fixture">
                    {betslip?.competitors[0]?.name ?? "-"} vs{" "}
                    {betslip?.competitors[1]?.name ?? "-"} @{" "}
                    <span className="BLM-betSlip-fixture">
                    {new Date(
                        parseInt(betslip?.match_date.$date.$numberLong)
                    ).toLocaleString()}
                  </span>
                  </div>
                </div>
              </div>
              <div
                  class="BLM-betSlipBox-close"
                  data-selectionid="316625476"
                  onClick={() => {
                    dispatch(setBets(Bets.filter((b) => b._id != betslip._id)));
                  }}
              >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                >
                  <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div>
            </div>
          </>
      );
    });
  };

  return (
      <div className="bg-light rounded">
        <div
            className="p-2"
            style={{
              backgroundColor: "#2c2e83",
              display: "flex",
              justifyContent: "space-between",
            }}
        >
          <b className="text-light">Betslip</b>
          <div>
            {/*{*/}
            {/*  printData?.length!=0 ?*/}
            {/*      <ComponentToPrint ref={componentRef}  data={printData}/>*/}
            {/*      : ''*/}
            {/*}*/}
            <ComponentToPrint ref={componentRef}  data={printData}/>

            <button style={{ background: "none", border: "none" }}>
              <svg
                  onClick={() => {
                    handlePrint()
                    // callTicket(29417)
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  className="bi bi-printer"
                  viewBox="0 0 16 16"
                  title="Print"
              >
                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="pl-2 main-slip-st">{_renderBetslips(1)}</div>
        <div className="BLM-betSlip-acceptOddsChange-container p-2">
          <div className="BLM-betSlip-acceptOddsChange w-75">
            <div className="BLM-checkbox mb-2">
              <input
                  type="checkbox"
                  id="acceptOddschange"
                  onClick={(e) => {
                    setAcceptOddsChange(!acceptOddsChange);
                  }}
                  selected={acceptOddsChange ? true : false}
              />
              <label htmlFor="acceptOddschange" />
              <span className="BLM-checkboxLabel">Accept Odds Change</span>
            </div>
            <div className="BLM-checkbox mb-2">
              <input
                  onClick={(e) => {
                    setKeepBet(!keepBet);
                  }}
                  selected={keepBet ? true : false}
                  type="checkbox"
                  id="keepPlacedBets"
              />
              <label htmlFor="keepPlacedBets" />
              <span className="BLM-checkboxLabel">Keep bets in betslip</span>
            </div>
          </div>
          <div
              onClick={() => {
                dispatch(setBets([]));
              }}
              className="BLM-betSlip-clearAll"
          >
            Clear All
          </div>
        </div>
        {
          <div className="row img_row">
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
                // isOpen={openModel}
                // style={customStyles}

            ><div  className="modal-dialog modal-dialog-centered "
                   role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">BetSlip Confirmation </h5>
                  <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                  >
                    <span aria-hidden="true" style={{ color: "#7e7e7e" }}>
                      ×
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  <div
                      className="BLM-betSlip-content"
                      style={{ overflowY: "auto",height: "45vh" }}
                  >
                    <div className="pl-2 main-slip-st">{_renderBetslips(0)}</div>
                  </div>
                  <div className="BLM-betSlip-footer" style={{backgroundColor:"#e7eaf7",paddingTop:"15px"}}>
                    { (note) ?
                        <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                          {" "}

                          <a href="/" className="text-danger">
                            {note}
                          </a>

                        </div> : null}
                    <div className="BLM-totalStakeOddsWins">
                      <div className="BLM-accordion" id="getBetslipAccordianId">
                        <div className="BLM-accordion-header BLM-BetslipArrowAfter">
                          <div className="BLM-totalStakeOddsWins-content" style={{marginLeft:"8px"}}>
                            <div className="BLM-contentBlock font-cls">
                              <div className="BLM-leftContent">Total Odds:</div>
                              <div className="BLM-rightContent">{totalOddNew}</div>
                            </div>
                            <div className="BLM-contentBlock BLM-wht font-cls">
                              <div className="BLM-leftContent">
                                Stake after tax:
                              </div>
                              <div
                                  className="BLM-rightContent"
                                  id="bindDeductedStakeValue"
                              >
                                 {(stake - exciseTax).toFixed(2)}
                              </div>
                            </div>
                            <div className="BLM-contentBlock BLM-wht font-cls">
                              <div className="BLM-leftContent">Excise Tax:</div>
                              <div
                                  className="BLM-rightContent"
                                  id="bindExciseValue"
                              >
                                 {exciseTax.toFixed(2)}
                              </div>
                            </div>
                            <div className="BLM-contentBlock BLM-wht font-cls">
                              <div className="BLM-leftContent">WHT:</div>
                              <div className="BLM-rightContent" id="bindTaxValue">
                                 {WHT}
                              </div>
                            </div>
                            {multiBonusPercent && multiBonusPercent!=0 ?
                                <div className="BLM-contentBlock">
                                  <div className="BLM-leftContent BLM-txtHighlight font-cls">
                                    {multiBonusPercent}% Boost:
                                  </div>
                                  <div
                                      className="BLM-rightContent BLM-txtHighlight font-cls"
                                      id="bindWinningValue"
                                  >
                                     {multiBonusAmount}
                                  </div>
                                </div>
                                :''}
                            <div className="BLM-contentBlock">
                              <div className="BLM-leftContent BLM-txtHighlight font-cls">
                                To Win:
                              </div>
                              <div
                                  className="BLM-rightContent BLM-txtHighlight font-cls"
                                  id="bindWinningValue"
                              >
                                 {WIN}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CountDown seconds={15} />
                    </div>
                    <p style={{fontSize: "10px",color: "red",marginTop: "10px"}}>By pressing “Bet Confirmation” you accept the above choices, or let timer run out to change</p>
                    <div><button className="btn BLM-btnPrimary active"  onClick={() => {
                      _submitBet();
                    }} style={{height: "35px",
                      fontSize: "12px",marginTop: "7px" }} >Bet Confirmation</button></div>

                  </div>
                </div>
              </div>
            </div>

            </div>
          </div>
        }
        {/* --------------------------- footer --------------------------- */}
        <div className="BLM-betSlip-footer">
          {
            (isAllPrematch==false)?
                <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                  {" "}

                  <a href="/" className="text-danger">
                    Please remove live matches to get Bonus
                  </a>

                </div> :
                (addMoreCount && bonusPercent) ? (
                    <div className="BLM-addSelections-infoMsg" id="betInfoMsg">
                      {" "}

                      <a href="/" className="text-danger">
                        Add {addMoreCount} more selections to receive a bonus of {bonusPercent}%
                      </a>
                      {(notApplicableOdds > 0) ?

                          <button style={{background: '#ebefff', border: "none" }}  title={`All selections value should be greater than  ${minimumOdds} to receive a bonus`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                              <path
                                  d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                            </svg>
                          </button> : ""}
                    </div>
                ) : ""
          }

          {/*<div className="BLM-addSelections-infoMsg" id="betInfoMsg">*/}
          {/*  {" "}*/}
          {/*  <a href="/" className="text-danger">*/}
          {/*    Add {addMoreCount} more selections to receive a bonus of {bonusPercent}%*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className="BLM-stakeBox-container">
            <div className="BLM-freeBet" />
            <div id="displayStakeBox">
              <div className="BLM-stakeInputBox BLM-form-control">
                {" "}
                {/*<span className="font-cls p-2">KSH</span>*/}
                <input
                    type="number"
                    className="w-50"
                    placeholder="Stake"
                    id="stakeValue"
                    value={stake}
                    onChange={(e) => {
                      setStake(e.target.value);
                    }}
                />
                <i className="sb-icon_close BLM-closeIcon p-2" />
              </div>
            </div>
            <div id="displayFreeBetDropDown" />
          </div>
          <div className="BLM-totalStakeOddsWins">
            <div className="BLM-accordion" id="getBetslipAccordianId">
              <div className="BLM-accordion-header BLM-arrowAfter">
                <div className="BLM-totalStakeOddsWins-content">
                  <div className="BLM-contentBlock font-cls">
                    <div className="BLM-leftContent">Total Odds:</div>
                    <div className="BLM-rightContent">{totalOdds}</div>
                  </div>
                  <div className="BLM-contentBlock BLM-wht font-cls">
                    <div className="BLM-leftContent">Stake after tax:</div>
                    <div className="BLM-rightContent" id="bindDeductedStakeValue">
                       {(stake - exciseTax).toFixed(2)}
                    </div>
                  </div>
                  <div className="BLM-contentBlock BLM-wht font-cls">
                    <div className="BLM-leftContent">Excise Tax:</div>
                    <div className="BLM-rightContent" id="bindExciseValue">
                       {exciseTax.toFixed(2)}
                    </div>
                  </div>
                  <div className="BLM-contentBlock BLM-wht font-cls">
                    <div className="BLM-leftContent">WHT:</div>
                    <div className="BLM-rightContent" id="bindTaxValue">
                       {_getPossibleWHT()}
                    </div>
                  </div>
                  {selectedPercent && selectedPercent!=0 ?
                      <div className="BLM-contentBlock">
                        <div className="BLM-leftContent BLM-txtHighlight font-cls">
                          {selectedPercent}% Boost:
                        </div>
                        <div
                            className="BLM-rightContent BLM-txtHighlight font-cls"
                            id="bindWinningValue"
                        >
                           {selectedBonus}
                        </div>
                      </div>
                      :''}
                  <div className="BLM-contentBlock">
                    <div className="BLM-leftContent BLM-txtHighlight font-cls">
                      To Win:
                    </div>
                    <div
                        className="BLM-rightContent BLM-txtHighlight font-cls"
                        id="bindWinningValue"
                    >
                       {_getPossibleWin()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="BLM-placeBet-container p-2">
            <div id="displayPlaceBetButton">
              <button
                  className="btn BLM-btnPrimary active BLM-btnLarge BLM-btn BLM-btnPlaceBet"
                  onClick={() => {
                    _handleSubmitBet();
                  }}
              >
                <span className="BLM-btnTxt text-light">Place Bet</span>
              </button>
            </div>{" "}
            <div id="displayAcceptOddsButton" />
          </div>
        </div>
      </div>
  );
};
