import React, {useEffect, useState} from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import DataTable from "react-data-table-component";
import axios from "axios";
import {API_URL} from "../../Common/constants";
import {getTicketBetData} from "../../Common/repository";
import {UncontrolledTooltip} from "reactstrap";

export const Reports = () => {
  const [item, setItem] = useState("All")
  const [data, setData] = useState()
  const [selectedMonth, setSelectedMonth] = useState(moment().format('MM'));

  let userData = "";
  if (typeof localStorage.users !== "undefined") {
    userData = JSON.parse(localStorage.getItem("users"))
  }
  useEffect(() => {
    // moment().format();

    $('input[name="datetimes"]').daterangepicker({
      timePicker: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "M/DD",
      },
    });
  }, []);

  useEffect(() => {
    call()
  },[])



  const call = () => {
    axios({
      method: "get",
      url: `${API_URL}/v4/cashier/report?month=${selectedMonth}&type=${item}&userId=${userData?.id}`
    }).then(function (res){
      setData(res?.data?.data ?? [])
    }).catch(function (response) {})
  }

  const columns = [
    {
      name: "Day Of Month",
      sortable: true,
      selector: (row) => <>{row.day}</>,
    },
    {
      name: "Bets",
      sortable: true,
      selector: (row) => <>{row.bets}</>,
    },
    {
      name: "Total Stake",
      sortable: true,
      selector: (row) => (
          <>{row.total_stake}</>
      ),
    },
    {
      name: "Effective Stake",
      sortable: true,
      selector: (row) => (
          <>{row.effective_stake}</>
      ),
    },
    {
      name: "Boost",
      sortable: true,
      selector: (row) => <>{row.boost}</>,
    },
    {
      name: "WHT",
      sortable: true,
      selector: (row) => (
          <>{row.wht}</>
      ),
    },
    {
      name: "Pay Out",
      sortable: true,
      selector: (row) => <>{row.payout}</>,
    },
    {
      name: "GGR",
      sortable: true,
      selector: (row) => <>{row.ggr}</>,
    },
    {
      name: "Payable Cash",
      sortable: true,
      selector: (row) => <>{row.payable_cash}</>,
    }
  ]



  return (
      <AppLayout>
        <div className="row">
          <div className="col-lg-9">{/* <BetsNav /> */}</div>
          <div className="col-lg-12 bg-light p-3" style={{ borderRadius: "5px" }}>
            <div className="row">
              <div className="col-lg-9 d-flex">
                <div className="col-lg-4 mb-2">
                  <label
                      style={{
                        fontSize: "12px",
                        margin: "0",
                        fontWeight: "bold",
                      }}
                  >
                    Month
                  </label>
                  <select
                      maxMenuHeight={150}
                      className="form-select"
                      aria-label="Default select example"
                      style={{ fontSize: "12px" }}
                      onChange={item => {
                        setSelectedMonth(item.target.value)
                      }}
                      value={selectedMonth}
                  >
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-4 mb-2" style={{marginLeft:"5px"}}>
                  <label
                      style={{
                        fontSize: "12px",
                        margin: "0",
                        fontWeight: "bold",
                      }}
                  >
                    Product
                  </label>
                  <select
                      className="form-select"
                      aria-label="Default select example"
                      style={{ fontSize: "12px" }}
                      onChange={item => {
                        setItem(item.target.value)
                      }}
                  >
                    <option selected>All</option>
                    <option value="Sports">Sports</option>
                    <option value="Virtual">Virtual</option>
                  </select>
                </div>

              </div>
              <div className="col-lg-2 pt-3">
                <button
                    className="form-control"
                    style={{ backgroundColor: "#3b8ab9", color: "white" }}
                    onClick={call}
                >
                  Submit
                </button>
              </div>
            </div>
            <DataTable
                defaultSortFieldId={1}
                style={{ width: "800px" }}
                columns={columns}
                data={data}
                pagination
            />
          </div>

        </div>
      </AppLayout>
  );
};
