import React, {useEffect, useState} from "react";
import { AppLayout } from "../../../layouts/AppLayout";
import DataTable from "react-data-table-component";
import { BetsNav } from "./BetsNav";
import "./bets.css";
import {cancelTicketCall, getTicketBetData, getUserInfo, payTicketCall} from "../../Common/repository";
import axios from "axios";
import {API_URL} from "../../Common/constants";
import toastr from "toastr";
import {setBalance} from "../../../store/Cashbalance/action";
import {useDispatch} from "react-redux";
import {UncontrolledTooltip} from "reactstrap";

export const Bets = () => {
  const [betData, setBetData] = useState()
  const [modalData, setModalsData] = useState([])
  const [fromDate, setFromDate] =useState(moment().format("YYYY-MM-DD"))
  const [toDate, setToDate] =useState(moment().format("YYYY-MM-DD"))
  const [item, setItem] = useState("All")
  const [gameType, setGameType] = useState("sport")
  const [successPin, setSuccessPin] = useState(false)
  let dispatch = useDispatch();
  let userData = "";
  if (typeof localStorage.users !== "undefined") {
    userData = JSON.parse(localStorage.getItem("users"))
  }
  useEffect(() => {
    call()
  },[])

  function diffMinutes(date1) {
    var currentTime = new Date().toISOString();
    const d1 = new Date(date1).getTime();
    const d2 = new Date(currentTime).getTime();
    return Math.round((d2 - d1) / 60000); // Can use Math.floor or Math.ceil depends up to you
  }


  const call = () => {

    axios({
      method: "get",
      url: `${API_URL}/v4/cashier/bet-list?to=${toDate}&from=${fromDate}&type=${item}&gameType=${gameType}&userId=${userData?.id}`,
    })
        .then(function (res) {
          setBetData(res?.data?.data ?? [])
          getUserInfo().then((res) => {
            dispatch(setBalance(res?.data?.data?.cash_balance))
          })
        })
        .catch(function (response) {})
  }

  const payTicket = (id,pin) => {
    if(pin.trim().length==0){
      toastr.error(`Please enter pin!!!`);
      return;
    }
    else{
      payTicketCall(id,pin).then((res) => {
        $('.pin-textbox').val('');
        toastr.success(res?.data?.message)
        call();
        setSuccessPin(true);
      }).catch(function (response) {
        toastr.error(response?.response?.data?.error);
      })
    }
  }

  const cancelTicket = (id) => {
    cancelTicketCall(id).then((res) => {
      toastr.success(res?.data?.message)
      call();
    }).catch(function (response) {
      toastr.error(response?.response?.data?.error);
    })

  }

  const openModal = (id) => {
    getTicketBetData(id).then((res) => {
      setModalsData(res?.data?.data[0] ?? [])
      $('#exampleModalCenter').modal('show');
    })
  }
  const _renderOddName = (f, odd) => {
    if (odd?.player) {
      return odd?.player?.name;
    }
    let text = odd?.market_spec?.name ?? "";

    text = text.replaceAll("{$competitor1}", f?.competitors[0]?.name);
    text = text.replaceAll("{$competitor2}", f?.competitors[1]?.name);
    if(text.includes('{total}')){
      text = text.replaceAll("{total}",odd?.special_bet_value);
    }
    else{
      if(odd?.special_bet_value){
        text = text + ` {${odd?.special_bet_value}}`
      }
    }

    return text;
  };

  useEffect(() => {
    if(successPin){
      $('#exampleModalCenter1').modal('show');
    }
    else{
      $('#exampleModalCenter1').modal('hide');
    }
  },[successPin])

  useEffect(() => {
    // moment().format();

    $('input[name="datetimes"]').daterangepicker({
      timePicker: true,
      startDate: moment().startOf("hour"),
      endDate: moment().startOf("hour").add(32, "hour"),
      locale: {
        format: "M/DD hh:mm A",
      },
    });
  }, []);

  const modalColumns = [
    {
      name: "Time",
      selector: (row) => <>{" "}
        {new Date(Date.parse(row?.created_at)).toLocaleDateString('en-GB')}{" "}
        {new Date(Date.parse(row?.created_at)).toLocaleTimeString()}</>,
      sortable: true,
      wrap: true,
      // grow: 1.5,
      width: "200px"
    },
    {
      name: "Event",
      selector: (row) =>
          <>
            {row.odd?row.odd.fixture_market.fixture.tournament.sport.name:""}
            <img src={`/assets/images/information.png`} id={`addtooltip${row?.id}`} className="tooltipCls"/>

            <UncontrolledTooltip placement="right" target={`addtooltip${row?.id}`} style={{'width':'100%'}}>
              <p style={{'margin-bottom':'0px','text-align':'left'}}>Sport: {row.odd?row.odd.fixture_market.fixture.tournament.sport.name:""}</p>
              <p style={{'margin-bottom':'0px','text-align':'left'}}>Location: {row.odd?row.odd.fixture_market.fixture.tournament.category.name:""}</p>
              <p style={{'margin-bottom':'0px','text-align':'left'}}>League: {row.odd?row.odd.fixture_market.fixture.tournament.name:""}</p>
              <p style={{'margin-bottom':'0px','text-align':'left'}}>Event: {row.odd?row.odd.fixture_market.fixture.competitors[0].name+' vs':""} {row.odd?row.odd.fixture_market.fixture.competitors[1].name:""}</p>
            </UncontrolledTooltip>
          </>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) =>  <>{row.bets?.length == 1 ? "Single" : "Multiple"}</>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Fixture",
      selector: (row) => (
          <>{row.odd?row?.odd?.fixture_market?.fixture?.competitors[0]?.name+' vs':""}  {row.odd?row?.odd?.fixture_market?.fixture?.competitors[1]?.name:""}</>
      ),
      sortable: true,
      wrap: true,
      width: "250px"
    },
    {
      name: "Odds",
      selector: (row) =>  <>{row?.price.toFixed(2)}</>,
      sortable: true,
      wrap: true,
      width:"70px"
    },
    {
      name: "Selection",
      selector: (row) =>      <>
        {(row?.odd?.length != 0 && row?.odd!=null)
            ?
            _renderOddName(
                row?.odd?.fixture_market?.fixture,
                row?.odd
            ) : ''
        }
        <img src={`/assets/images/information.png`} id={`addtooltip2${row?.id}`} className="tooltipCls"/>
        <UncontrolledTooltip placement="top" target={`addtooltip2${row?.id}`}>
          <p style={{'margin-bottom':'0px'}}>Market: {row.odd?row.odd.fixture_market.market.name:""}</p>
        </UncontrolledTooltip>


      </>,
      sortable: true,
      wrap: true,
      width: "250px"
    },
    {
      name: "Status",
      selector: (row) =>
          <>
            <a
                className={`btn btn-sm btns ${(row?.is_cancel_by_admin) ? "btn-info" :
                    (row?.status == null && parseFloat(row?.void_factor) == parseFloat(0) && row.out_come_for_canceled==null && row.is_won==null) ? 'btn-info' :
                        (row?.status) ? "btn-success" : ((row?.out_come_for_canceled == 'N' || row?.out_come_for_canceled == 'C') ? "btn-secondary" : ((parseFloat(row?.void_factor) != parseFloat(0)) ? "btn-primary" : ((row?.is_won) ? "btn-success" : "btn-danger")))}`}
            >
              {
                (row?.is_cancel_by_admin) ? "Void" :
                    (row?.status == null && parseFloat(row?.void_factor) == parseFloat(0) && row.out_come_for_canceled==null && row.is_won==null) ? 'Pending' :

                        (row?.status) ? "Won" : ((row?.out_come_for_canceled == 'N' || row?.out_come_for_canceled == 'C') ? "Cancel" : ((parseFloat(row?.void_factor) != parseFloat(0)) ? "Void" : ((row?.is_won) ? "Won" : "Lost")))
              }

            </a>
          </>,
      sortable: true,
      wrap: true,
    },

  ];

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      wrap: true
    },
    {
      name: "Username",
      selector: (row) => row?.user?.username,
      wrap: true
    },
    {
      name: "Channel",
      selector: (row) => "Retail",
      wrap: true
    },
    // {
    //   name: "Product",
    //   selector: (row) => row?.product,
    // },
    {
      name: "Type of Bet",
      selector: (row) => (row.bets?.length == 1 ? "Single" : "Multiple"),
      wrap: true
    },
    {
      name: "Date/Time",
      selector: (row) => <>{" "}
        {new Date(Date.parse(row?.created_at)).toLocaleDateString('en-GB', {
          month: '2-digit',day: '2-digit',year: 'numeric'})}{" "}
        {new Date(Date.parse(row?.created_at)).toLocaleTimeString()}</>,
      wrap: true
    },
    {
      name: "Stake",
      selector: (row) => row.stake,
      wrap: true
    },
    {
      name: "Stake Tax",
      selector: (row) => <>{parseFloat(row.stake - row.stake_after_tax).toFixed(2)}</>,
      wrap: true
    },
    {
      name: "Boost",
      selector: (row) => row.bonus_amount ?? 0,
      wrap: true
    },
    {
      name: "WHT",
      selector: (row) => parseFloat(row.wht).toFixed(2),
      wrap: true
    },
    {
      name: "NetWin",
      selector: (row) => parseFloat(row.to_win).toFixed(2),
      wrap: true
    },
    {
      name: "Bet Status",
      selector: (row) =>   <>{row.status == 1 ? "won" : row.status == 0 ? "lost" : row.status == 2 ? "void" : "pending"}</>,
      wrap: true
    },
    {
      name: "Actions",
      selector: (row) => <>
        {
          (row.status==1 || (row.status==2 && row.is_cancel_by_cashier==0) ) ?
              (row.cashier_paid_status==null) ?
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target={`#exampleModalCenter${row?.id}`}
                        onClick={() => {openModal(row?.id)}}
                        className="prev-btn"
                    >
                      Preview
                    </button>
                    <input type="text" placeholder="PIN" id={`pinText${row?.id}`} className="pin-textbox" maxLength={4} />
                    <button className="pay-btn" onClick={() => { payTicket(row?.id,document.getElementById(`pinText${row?.id}`).value) }}>{row.status == 1 ? "Pay Ticket" : "Refund"}</button>
                  </div>
                  :
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target={`#exampleModalCenter${row?.id}`}
                        onClick={() => {openModal(row?.id)}}
                        className="prev-btn"
                        style={{marginBottom: "4px"}}
                    >
                      Preview
                    </button>
                    <span className="badge badge-secondary" style={{backgroundColor:"green"}}>Paid</span>
                  </div>
              : ((row.cashier_paid_status==null && row.status==null) || row.status==0) ?
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target={`#exampleModalCenter${row?.id}`}
                        onClick={() => {openModal(row?.id)}}
                        className="prev-btn"
                        style={{marginBottom: "4px"}}
                    >
                      Preview
                    </button>
                    {
                      (diffMinutes(row?.created_at)<=5 && row.is_cancel_by_cashier==0) ?
                          <button className="btn btn-secondary" style={{margin: "5px 0 0px 0",fontSize: "inherit"}}  onClick={() => { cancelTicket(row?.id) }}>Cancel</button>
                          :''
                    }

                  </div>
                  :
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                        type="button"
                        data-toggle="modal"
                        data-target={`#exampleModalCenter${row?.id}`}
                        onClick={() => {openModal(row?.id)}}
                        className="prev-btn"
                        style={{marginBottom: "4px"}}
                    >
                      Preview
                    </button>
                  </div>

        }

      </>,
      wrap: true
    },
  ];

  return (
      <>
        <AppLayout>
          <div className="row">
            <div className="col-lg-9">
              <BetsNav
                  gameType={gameType}
                  setGameType={setGameType}
                  call={() => {
                    call()
                  }}
              />
            </div>
            <div
                className="col-lg-12 bg-light p-3"
                style={{ borderRadius: "5px" }}
            >
              <div className="row">
                <div className="col-lg-9 d-flex">
                  <div className="col-lg-4 mb-2" style={{marginRight: "3px"}}>
                    <label
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          fontWeight: "bold",
                        }}
                    >
                      From Date
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="horizontal-firstname-Input"
                        onChange={e => {
                          setFromDate(e.target.value)
                        }}
                        value={fromDate}
                    />
                  </div>
                  <div className="col-lg-4 mb-2" style={{marginRight: "3px"}}>
                    <label
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          fontWeight: "bold",
                        }}
                    >
                      To Date
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="horizontal-firstname-Input"
                        onChange={e => {
                          setToDate(e.target.value)
                        }}
                        value={toDate}
                    />

                  </div>
                  <div className="col-lg-4 mb-2">
                    <label
                        style={{
                          fontSize: "14px",
                          margin: "0",
                          fontWeight: "bold",
                        }}
                    >
                      Ticket Type
                    </label>
                    <select
                        className="form-select"
                        aria-label="Default select example"
                        style={{ fontSize: "14px",height: "38px" }}
                        onChange={item => {
                          setItem(item.target.value)
                        }}
                    >
                      <option value="All" selected>All</option>
                      <option value="Lost">Lost</option>
                      <option value="Won">Won</option>
                      <option value="Paid">Paid</option>
                      <option value="Pending">Pending</option>
                      <option value="Void">Void</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 pt-4">
                  <button
                      className="form-control"
                      style={{ backgroundColor: "#3b8ab9", color: "white" }}
                      onClick={call}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <DataTable
                  defaultSortFieldId={1}
                  style={{ width: "800px" }}
                  columns={columns}
                  data={betData}
                  pagination
              />
            </div>
            {
              (Object.keys(modalData).length !== 0) ?
                  <div
                      className="modal fade"
                      id="exampleModalCenter"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalCenterTitle"
                      aria-hidden="true"
                  >
                    <div
                        className="modal-dialog modal-dialog-centered modal-xl"
                        role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLongTitle">
                            Ticket {modalData?.id}
                          </h5>
                          <button
                              type="button"
                              className="close"
                              data-bs-dismiss="modal"
                              // data-dismiss="modal"
                              aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          {/* -------------------------------------- modal body---------------------- */}

                          <div className="row mb-3">
                            <div className="col-lg-3">
                              <div className="text-white-50  card bg-primary">
                                <div className="card-body">
                                  <div className="mb-4 text-white card-title">
                                    Total Stake
                                  </div>
                                  <p className="text-white card-text">{modalData.stake?parseFloat(modalData.stake).toFixed(2):0}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="text-white-50  card bg-success">
                                <div className="card-body">
                                  <div className="mb-4 text-white card-title">
                                    Effective Stake
                                  </div>
                                  <p className="text-white card-text">{modalData.stake_after_tax?parseFloat(modalData.stake_after_tax).toFixed(2): 0}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="text-white-50  card bg-info">
                                <div className="card-body">
                                  <div className="mb-4 text-white card-title">
                                    Boost
                                  </div>
                                  <p className="text-white card-text">{modalData.bonus_amount?parseFloat(modalData.bonus_amount).toFixed(2):0}</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="text-white-50  card bg-warning">
                                <div className="card-body">
                                  <div className="mb-4 text-dark card-title">
                                    Possible Win
                                  </div>
                                  <p className="text-dark card-text">{parseFloat(modalData?.to_win).toFixed(2)}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="card">
                                <div className="card-header bg-light border-bottom">
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="card-title text-nowrap">
                                            Bet Type:
                                          </div>
                                        </div>
                                        <div className="col-lg-8 me-t">
                                        <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                          {modalData.bets?.length == 1 ? "Single" : "Multiple"}
                                        </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-6" />
                                    <div className="col-lg-3">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="card-title text-nowrap">
                                            Stake Tax:
                                          </div>
                                        </div>
                                        <div className="col-lg-6 me-t">
                                  <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                    {parseFloat(modalData.stake - modalData.stake_after_tax).toFixed(2)}
                                  </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3">
                                      <div className="row">
                                        <div className="col-lg-3">
                                          <div className="card-title text-nowrap">
                                            Log:
                                          </div>
                                        </div>
                                        <div className="col-lg-9">
                                          <div>
                                            <span className="badge-soft-dark me-1 badge badge-secondary badge-pill">
                                              {" "}
                                              {new Date(Date.parse(modalData?.created_at)).toLocaleDateString('en-GB')}{" "}
                                              {new Date(Date.parse(modalData?.created_at)).toLocaleTimeString()}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-lg-2" />
                                      </div>
                                    </div>
                                    <div className="col-lg-6" />
                                    <div className="col-lg-3">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="card-title text-nowrap">
                                            WHT:
                                          </div>
                                        </div>
                                        <div className="col-lg-8 me-t">
                                          <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                           {parseFloat(modalData.wht).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-3"/>
                                    <div className="col-lg-6" />
                                    <div className="col-lg-3">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          <div className="card-title text-nowrap">
                                            Total Odds:
                                          </div>
                                        </div>
                                        <div className="col-lg-8 me-t">
                                          <span className="me-2 bg-primary text-nowrap badge badge-secondary">
                                           {parseFloat(modalData.total_odds).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <DataTable
                                      defaultSortFieldId={1}
                                      style={{ width: "1000px" }}
                                      columns={modalColumns}
                                      data={modalData?.bets}
                                      paginationPerPage={5}
                                      pagination
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  : ''
            }

            {(successPin) ?
                <div
                    className="modal fade"
                    id="exampleModalCenter1"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                  <div
                      className="modal-dialog modal-dialog-centered modal-md"
                      role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">
                          Paid Confirmation
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-bs-dismiss="modal"
                            // data-dismiss="modal"
                            aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-lg-12">
                            <h5>Ticket mark as paid successfully</h5>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success" data-bs-dismiss="modal">Ok</button>
                      </div>
                    </div>
                  </div>

                </div>
                : ''}

          </div>
        </AppLayout>
      </>
  );
};
